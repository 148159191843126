// Imports => MOBX
import { observable, computed, action, makeObservable, toJS } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcIsSet,
	AcIsUndefined,
	AcIsNull,
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcRemoveState,
	AcClearState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
} from '@utils';

const _default = {
	options: {},
	profile: {
		activated: null,
		active_subscription: null,
		active_subscription_next_year: null,
		bank_account: null,
		can_apply_for_new_subscription: null,
		created_at: null,
		documents: null,
		email: null,
		first_contract_date: null,
		first_name: null,
		full_name: null,
		id: null,
		interests: null,
		last_name: null,
		on_waiting_list: null,
		phone: null,
		phone_2: null,
		registration_date: null,
		rental_object: null,
		residents: null,
		updated_at: null,
	},
};

let app = {};

export class ProfileStore {
	constructor(store) {
		makeObservable(this);

		AcAutoLoad(this, KEYS.PROFILE);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	profile = _default.profile;

	@computed
	get current_profile() {
		return toJS(this.profile);
	}

	@observable
	loading = {
		status: false,
		message: null,
	};

	@action
	setLoading(state, message) {
		this.loading = {
			status: state || false,
			message: message || 'Checking your profile',
		};
	}

	@computed
	get is_loading() {
		return toJS(this.loading.status);
	}

	@observable
	busy = {
		status: false,
		message: null,
	};

	@action
	setBusy(state, message) {
		this.busy = {
			status: state || false,
			message: message || 'Checking your profile',
		};
	}

	@computed
	get is_busy() {
		return toJS(this.busy.status);
	}

	@action
	who_am_i = () => {
		this.setLoading(true);

		return app.store.api.profile
			.whoami()
			.then((response) => {
				this.set(KEYS.PROFILE, response.data, true);

				this.setLoading(false);

				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het ophalen van jouw profiel en instellingen`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	@action
	update_password = (data) => {
		this.setBusy(true);

		return app.store.api.profile
			.update_password(data)
			.then((response) => {
				app.store.toasters.add({
					variant: 'success',
					delay: 5000,
					title: `Jouw wijzigingen zijn opgeslagen`,
				});

				this.setBusy(false);

				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het wijzigen van jouw profiel en instellingen`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setBusy(false);
				throw error;
			});
	};

	@action
	extend_subscription = (data) => {
		this.setBusy(true);

		return app.store.api.profile
			.extend_subscription()
			.then((response) => {
				app.store.toasters.add({
					variant: 'success',
					delay: 5000,
					title: `De verlenging van jouw inschrijving is ontvangen`,
				});

				this.setBusy(false);

				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het verlengen van jouw inschrijving`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setBusy(false);
				throw error;
			});
	};

	@action
	set = (target, value, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (AcIsUndefined(value)) return;

		return new Promise((resolve) => {
			this[target] = value;
			if (save) AcSaveState(target, value);
			resolve();
		});
	};

	@action
	setState = (target, property, value, save) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (!AcIsSet(property)) return;
		if (AcIsUndefined(value)) return;

		this[target][property] = value;
		if (save) AcSaveState(target, value);
	};

	@action
	reset = (target, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;

		return new Promise((resolve) => {
			runInAction(() => {
				this[target] = _default[target];
			});

			if (save && AcIsNull(_default[target])) {
				AcRemoveState(target);
			} else if (save) {
				AcSaveState(target, _default[target]);
			}

			resolve();
		});
	};
}

export default ProfileStore;
